<template>
  <div class="preview">
    <img :src="state.src" v-if="state.src"/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "modalPreview";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "lg"});
      state.src = modalParams.src;
    });

    const state = reactive({
      src: "",
    });

    const modalParams = store.getters.modalParams(component);

    return {component, state};
  }
});
</script>

<style lang="scss" scoped>
.preview {
  background: #fff;

  img {
    border: $px1 solid #3c3c3c;
    width: 100%;
  }
}
</style>